@mixin button--primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid $red-dark;
  border-radius: 40px;
  background: $red-dark;
  color: $white;
  cursor: pointer;
  transition: all ease-out .125s;

  &:hover,
  &:focus {
    background: $red-darkest;
    border-color: $red-darkest;
    transition: all ease-out .075s;
  }
}

.form-submit,
.button--primary,
.button--primary.active {
  @include button--primary();
}

@mixin button--secondary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid $red-dark;
  border-radius: 25px;
  background: $white;
  color: $red-dark;
  cursor: pointer;
  transition: all ease-out .125s;

  &:hover,
  &:focus {
    color: $white;
    background: $red-darkest;
    border-color: $red-darkest;
    transition: all ease-out .075s;
  }
}

.button--secondary,
.button--secondary.active {
  @include button--secondary();
}

.button--account ,
.button--account.active {
  text-indent: -9999px;
  overflow: hidden;
  padding: 10px;

  &:before {
    content: '';
    display: flex;
    background: url('/sites/all/themes/ccsoccer/images/account--anon.svg') no-repeat;
    width: 20px;
    height: 20px;
  }
}

.logged-in .button--account:before {
  background: url('/sites/all/themes/ccsoccer/images/account--auth.svg') no-repeat;
}

.buttons,
.form-actions {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 30px;

  @include breakpoint(lg) {
    padding: 30px 0;
  }

  .button,
  input[type=submit]{
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

body.logged-in .button--anon {
  display: none;
}

body.not-logged-in .button--auth {
  display: none;
}
