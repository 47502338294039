.schedule {
  position: relative;
  margin: 0 auto;

  &__container {
    position: relative;
  }

  &__reel {
    overflow-x: hidden;
    overflow-y: visible;
  }

  &__table {
    width: 100%;
    margin-top: 30px;
    table-layout: fixed;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 186px;
    height: 100%;
    z-index: 99;
    text-indent: -99999px;
    position: absolute;
    top: 0;
    opacity: .25;
    cursor: pointer;

    &:hover {
      background-color: $orange;
      opacity: .75;
    }

    &:before {
      content:'';
      display: flex;
      height: 120px;
      width: 120px;
      background: url(/sites/all/themes/ccsoccer/images/chevron.svg);
      background-position: center;
      background-size: cover;
    }

    &--prev {
      left: 0;

      &:before {
        transform: rotate(180deg);
      }
    }

    &--next {
      right: 0;
    }
  }

  &__team {
    height: 20px;

    &--home {
      background: $red-lightest;
    }

    &--away {
      background: $white;
    }

    &--mine {

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        margin: -2px 4px 0 0;
        display: inline-block;
        background-image: url(/sites/default/files/soccer-ball-icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
    }
  }

  th {
    width: 186px;
    height: 40px;
    background: $gray-light;
    border-left: 10px solid $white;
    border-right: 10px solid $white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    &.header-0 {
      position: absolute;
    }
  }

  tr {
    padding-bottom: 2px;
    display: inline-flex;
  }

  td {
    vertical-align: middle;
    width: 186px;
    height: 40px;
    text-align: center;
    font-weight: bold;
    border-left: 10px solid $green-darkest;
    border-right: 10px solid $green-darkest;

    &.cell-0 {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $green-darkest;
      color: $white;
    }
  }
}
