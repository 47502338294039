.locations {
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
  }
}

.location {
  width: 100%;

  @include breakpoint(md) {
    width: 50%;
  }

  + .location {
    padding-top: 40px;
  }

  &:nth-child(odd) {

    @include breakpoint(md) {
      padding: 0 20px 0 0;
    }
  }

  &:nth-child(even) {

    @include breakpoint(md) {
      padding: 0 0 0 20px;
    }
  }

  iframe {
    padding: 20px 0 0;
  }
}
