*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background: $black;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul, menu {
  list-style: none;
}

li, li.expanded.expanded {
  list-style: none;
//   padding: 0;
  margin: 0;

  .leaf {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

button, .btn, .form-control, .link, .reset { // reset style of buttons + form controls
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  cursor: pointer;
}

select.form-control::-ms-expand {
  display: none; // hide Select default icon on IE
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none; // hide X icon in IE and Edge
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  height: auto;
  max-width: 100%;
}

// Global content container
.container {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}

// Drupal 7 Core UI issues
#admin-menu {
  height: auto;
}

#admin-menu .dropdown .admin-menu-icon a {
  padding: 4px 8px;
}

#site {
  overflow: hidden;
  background: $white;
}

.clearfix:after {
  display: none;
}

.block {
  position: relative;
}
