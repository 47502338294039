.view-league-summary {
  padding: 20px 0 0;

  @include breakpoint(md) {
    padding: 40px 0 0;
  }

  .view-content {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .views-row {
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }

    + .views-row {
      padding-top: 40px;
    }

    &:nth-child(odd) {

      @include breakpoint(md) {
        padding: 0 20px 0 0;
      }
    }

    &:nth-child(even) {

      @include breakpoint(md) {
        padding: 0 0 0 20px;
      }
    }
  }

  .field-name-field-images {
    padding: 20px 0;

    img {
      border-radius: 5px;
    }
  }

  .field-name-body {

    .button {
      margin-top: 10px;
    }
  }
}
