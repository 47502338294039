.block-masquerade {
  display: flex;
  justify-content: center;
  padding: 0 0 40px;

  @include breakpoint(md) {
    justify-content: flex-start;
  }

  .form-item {
    margin: 0;
    padding-right: 10px;
  }

  .description {
    padding: 4px 0 0;
    color: $gray;
  }
}
