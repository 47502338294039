.game-status {
  padding: 12px 12px 12px 69px;
  background: $gray-dark;
  position: relative;

  @include breakpoint(lg) {
    width: calc(100% - 300px);
    margin: 0 40px 0 260px;
    border-radius: 5px;
  }

  &:before {
    content: '';
    background: $black;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 55px;

    @include breakpoint(lg) {
      border-radius: 5px 0 0 5px;
    }
  }

  &:after {
    content: '';
    background: url('/sites/all/themes/ccsoccer/images/calendar.svg') no-repeat;
    width: 32px;
    height: 32px;
    position: absolute;
    top: calc(50% - 16px);
    left: 12px;
  }

  p {
    color: $white;
    padding: 0;
    margin: 0;
    line-height: 1.4;
  }

  &__details {
    font-size: 13px;
    font-weight: 700;

    @include breakpoint(lg) {
      font-size: 16px;
    }
  }

  &__disclaimer {
    font-size: 10px;
    font-weight: 400;
  }

  &--tbd {
    background: $gray-light;

    &:before {
      background: $gray;
    }

    &:after {
      background: url('/sites/all/themes/ccsoccer/images/clock.svg') no-repeat;
    }

    p {
      color: $gray-dark;
    }
  }

  &--on {
    background: $green-dark;

    &:before {
      background: $green-darkest;
    }

    &:after {
      background: url('/sites/all/themes/ccsoccer/images/thumb.svg') no-repeat;
    }
  }

  &--off {
    background: $red-dark;

    &:before {
      background: $red-darkest;
    }

    &:after {
      background: url('/sites/all/themes/ccsoccer/images/thumb.svg') no-repeat;
      transform: rotate(180deg);
    }
  }
}
