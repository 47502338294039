$font-teko: 'Teko', Impact, system-ui, sans-serif;
$font-open-sans: 'Open Sans', system-ui, sans-serif;

html, body {
  -webkit-font-smoothing: antialiased;
  font-family: $font-open-sans;
  color: $textColor;
  line-height: 1.2;
  font-synthesis: none;
}

h1, .h1.h1 {
  font-size: 38px;
  font-family: $font-teko;
  font-weight: 700;
  color: $red-darkest;
  text-transform: uppercase;
  line-height: .8;
  letter-spacing: -.01em;
  padding-bottom: 10px;

  @include breakpoint(lg) {
    font-size: 66px;
  }
}

h2, .h2.h2 {
  font-size: 32px;
  font-family: $font-teko;
  font-weight: 700;
  color: $red-darkest;
  text-transform: uppercase;
  line-height: .8;
  letter-spacing: -.01em;

  @include breakpoint(lg) {
    font-size: 45px;
  }
}

h3, .h3.h3 {
  font-size: 24px;
  font-family: $font-teko;
  font-weight: 700;
  color: $red-darkest;
  text-transform: uppercase;
  line-height: .8;
  letter-spacing: -.01em;

  @include breakpoint(lg) {
    font-size: 36px;
  }
}

h4, .h4.h4 {
  font-size: 20px;
  font-family: $font-open-sans;
  font-weight: 700;
  color: $black;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: -.015em;

  @include breakpoint(lg) {
    font-size: 24px;
  }
}

h5, .h5.h5 {
  font-size: 18px;
  font-family: $font-open-sans;
  color: $black;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: -.015em;

  @include breakpoint(lg) {
    font-size: 22px;
  }
}

h6, .h6.h6 {
  font-size: 16px;
  font-family: $font-open-sans;
  color: $black;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: -.015em;
}

p, .p1.p1 {
  line-height: 1.75;
  font-size: 16px;

  + p {
    padding: 16px 0 0;
  }

  b, strong {
    font-family: $font-open-sans;
    font-weight: 700;
  }
}

.p2.p2 {
  line-height: 1.75;
  font-size: 14px;
}

.p3.p3 {
  line-height: 1.5;
  font-size: 10px;
}

a {
  color: $linkColor;
  font-family: $font-open-sans;
  font-weight: 700;
}

sup {
  font-size: .5em;
}

q, blockquote {
  font-size: 18px;
  font-family: $font-open-sans;
  color: $textColor;
  padding: 16px 0 32px;
  line-height: 1.2;

  @include breakpoint(lg) {
    font-size: 32px;
  }
}

b, strong {
  font-weight: bold;
}

pre {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  padding: 8px;
  color: $white;
  background: $black;
  border: 2px solid $borderColor;
  margin: 12px 0 32px;
  border-radius: 10px;
  white-space: pre-line;
}
