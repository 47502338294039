#league-manager-menu,
#block-menu-menu-rules-menu {
  padding: 0 0 20px;

  ul.menu {
    display: flex;
    list-style: none;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      + li {
        padding: 0 0 0 10px;
      }
    }

    a {
      @include button--secondary;

      &.active-trail {
        @include button--primary;
      }
    }
  }
}
