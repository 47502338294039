@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fromRight {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fromLeft {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fromBottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes fromTop {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes rotateRight {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(14.5deg);
  }
}
@keyframes rotateLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-14.5deg);
  }
}
@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleDown {
  0% {
    opacity: 0;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background: #000000;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul, menu {
  list-style: none;
}

li, li.expanded.expanded {
  list-style: none;
  margin: 0;
}
li .leaf, li.expanded.expanded .leaf {
  list-style: none;
  padding: 0;
  margin: 0;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

button, .btn, .form-control, .link, .reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  cursor: pointer;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  height: auto;
  max-width: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

#admin-menu {
  height: auto;
}

#admin-menu .dropdown .admin-menu-icon a {
  padding: 4px 8px;
}

#site {
  overflow: hidden;
  background: #FFFFFF;
}

.clearfix:after {
  display: none;
}

.block {
  position: relative;
}

html, body {
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", system-ui, sans-serif;
  color: #000000;
  line-height: 1.2;
  font-synthesis: none;
}

h1, .h1.h1 {
  font-size: 38px;
  font-family: "Teko", Impact, system-ui, sans-serif;
  font-weight: 700;
  color: #6D0000;
  text-transform: uppercase;
  line-height: 0.8;
  letter-spacing: -0.01em;
  padding-bottom: 10px;
}
@media (min-width: 992px) {
  h1, .h1.h1 {
    font-size: 66px;
  }
}

h2, .h2.h2 {
  font-size: 32px;
  font-family: "Teko", Impact, system-ui, sans-serif;
  font-weight: 700;
  color: #6D0000;
  text-transform: uppercase;
  line-height: 0.8;
  letter-spacing: -0.01em;
}
@media (min-width: 992px) {
  h2, .h2.h2 {
    font-size: 45px;
  }
}

h3, .h3.h3 {
  font-size: 24px;
  font-family: "Teko", Impact, system-ui, sans-serif;
  font-weight: 700;
  color: #6D0000;
  text-transform: uppercase;
  line-height: 0.8;
  letter-spacing: -0.01em;
}
@media (min-width: 992px) {
  h3, .h3.h3 {
    font-size: 36px;
  }
}

h4, .h4.h4 {
  font-size: 20px;
  font-family: "Open Sans", system-ui, sans-serif;
  font-weight: 700;
  color: #000000;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: -0.015em;
}
@media (min-width: 992px) {
  h4, .h4.h4 {
    font-size: 24px;
  }
}

h5, .h5.h5 {
  font-size: 18px;
  font-family: "Open Sans", system-ui, sans-serif;
  color: #000000;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: -0.015em;
}
@media (min-width: 992px) {
  h5, .h5.h5 {
    font-size: 22px;
  }
}

h6, .h6.h6 {
  font-size: 16px;
  font-family: "Open Sans", system-ui, sans-serif;
  color: #000000;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: -0.015em;
}

p, .p1.p1 {
  line-height: 1.75;
  font-size: 16px;
}
p + p, .p1.p1 + p {
  padding: 16px 0 0;
}
p b, p strong, .p1.p1 b, .p1.p1 strong {
  font-family: "Open Sans", system-ui, sans-serif;
  font-weight: 700;
}

.p2.p2 {
  line-height: 1.75;
  font-size: 14px;
}

.p3.p3 {
  line-height: 1.5;
  font-size: 10px;
}

a {
  color: #023145;
  font-family: "Open Sans", system-ui, sans-serif;
  font-weight: 700;
}

sup {
  font-size: 0.5em;
}

q, blockquote {
  font-size: 18px;
  font-family: "Open Sans", system-ui, sans-serif;
  color: #000000;
  padding: 16px 0 32px;
  line-height: 1.2;
}
@media (min-width: 992px) {
  q, blockquote {
    font-size: 32px;
  }
}

b, strong {
  font-weight: bold;
}

pre {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  padding: 8px;
  color: #FFFFFF;
  background: #000000;
  border: 2px solid #33333A;
  margin: 12px 0 32px;
  border-radius: 10px;
  white-space: pre-line;
}

.form-submit,
.button--primary,
.button--primary.active {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 40px;
  background: #B80000;
  color: #FFFFFF;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
.form-submit:hover, .form-submit:focus,
.button--primary:hover,
.button--primary:focus,
.button--primary.active:hover,
.button--primary.active:focus {
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}

.button--secondary,
.button--secondary.active {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 25px;
  background: #FFFFFF;
  color: #B80000;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
.button--secondary:hover, .button--secondary:focus,
.button--secondary.active:hover,
.button--secondary.active:focus {
  color: #FFFFFF;
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}

.button--account,
.button--account.active {
  text-indent: -9999px;
  overflow: hidden;
  padding: 10px;
}
.button--account:before,
.button--account.active:before {
  content: "";
  display: flex;
  background: url("/sites/all/themes/ccsoccer/images/account--anon.svg") no-repeat;
  width: 20px;
  height: 20px;
}

.logged-in .button--account:before {
  background: url("/sites/all/themes/ccsoccer/images/account--auth.svg") no-repeat;
}

.buttons,
.form-actions {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 30px;
}
@media (min-width: 992px) {
  .buttons,
  .form-actions {
    padding: 30px 0;
  }
}
.buttons .button,
.buttons input[type=submit],
.form-actions .button,
.form-actions input[type=submit] {
  margin-right: 20px;
}
.buttons .button:last-child,
.buttons input[type=submit]:last-child,
.form-actions .button:last-child,
.form-actions input[type=submit]:last-child {
  margin-right: 0;
}

body.logged-in .button--anon {
  display: none;
}

body.not-logged-in .button--auth {
  display: none;
}

.fieldset-legend.fieldset-legend.fieldset-legend {
  display: none;
}

.url-textfield,
.login-account {
  display: none;
}

.form-item input,
.form-item select {
  outline: 0;
  outline: 0;
  padding: 10px;
  border: 2px solid #D6D6D9;
  border-radius: 3px;
}
.form-item .description {
  color: #33333A;
  font-size: 10px;
}

#league-manager-menu,
#block-menu-menu-rules-menu {
  padding: 0 0 20px;
}
#league-manager-menu ul.menu,
#block-menu-menu-rules-menu ul.menu {
  display: flex;
  list-style: none;
}
#league-manager-menu ul.menu li,
#block-menu-menu-rules-menu ul.menu li {
  list-style: none;
  padding: 0;
  margin: 0;
}
#league-manager-menu ul.menu li + li,
#block-menu-menu-rules-menu ul.menu li + li {
  padding: 0 0 0 10px;
}
#league-manager-menu ul.menu a,
#block-menu-menu-rules-menu ul.menu a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 25px;
  background: #FFFFFF;
  color: #B80000;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
#league-manager-menu ul.menu a:hover, #league-manager-menu ul.menu a:focus,
#block-menu-menu-rules-menu ul.menu a:hover,
#block-menu-menu-rules-menu ul.menu a:focus {
  color: #FFFFFF;
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}
#league-manager-menu ul.menu a.active-trail,
#block-menu-menu-rules-menu ul.menu a.active-trail {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 40px;
  background: #B80000;
  color: #FFFFFF;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
#league-manager-menu ul.menu a.active-trail:hover, #league-manager-menu ul.menu a.active-trail:focus,
#block-menu-menu-rules-menu ul.menu a.active-trail:hover,
#block-menu-menu-rules-menu ul.menu a.active-trail:focus {
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}

.header {
  display: flex;
  justify-content: space-around;
  background: #33333A;
  border-bottom: 4px solid #FF8F12;
  position: relative;
  z-index: 200;
}
@media (min-width: 992px) {
  .header {
    display: block;
    margin-bottom: 20px;
    border-bottom: 7px solid #FF8F12;
  }
}
.header .container {
  position: relative;
  height: 100%;
}
@media (min-width: 992px) {
  .header .container {
    height: auto;
  }
}
.header__logo {
  position: relative;
  z-index: 500;
  display: block;
  height: 80px;
  width: 70px;
  margin: 5px 0 -20px 20px;
  text-indent: -9999px;
  overflow: hidden;
  background: url("/sites/all/themes/ccsoccer/images/logo--color.svg") no-repeat;
  transition: transform ease-out 0.25s;
}
@media (min-width: 992px) {
  .header__logo {
    position: absolute;
    top: 10px;
    left: 40px;
    height: 200px;
    width: 180px;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .header__logo:hover, .header__logo:focus {
    transform: scale(1.025);
    transition: transform ease-out 0.125s;
  }
}
.header__slogan {
  display: none;
  color: #FFFFFF;
  background: #000000;
  font-weight: 400;
  margin: 0;
}
@media (min-width: 992px) {
  .header__slogan {
    display: block;
  }
}
.header__slogan p {
  display: block;
  padding: 10px 10px 10px 280px;
}
.header__menu {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 65px 0 0;
  position: absolute;
  top: 0;
  right: -100vw;
  height: 100vh;
  width: 100vw;
  background: #33333A;
  z-index: 300;
  transform: translateX(0);
  transition: transform ease-out 0.25s;
}
@media (min-width: 992px) {
  .header__menu {
    overflow: unset;
    padding: 0;
    position: relative;
    align-items: flex-start;
    top: auto;
    right: auto;
    height: auto;
    width: auto;
    background: transparent;
    transform: translateX(0);
    transition: transform linear 0;
  }
}
.header__menu.active {
  transform: translateX(-100%);
  transition: transform ease-out 0.125s;
}
@media (min-width: 992px) {
  .header__menu.active {
    transform: translateX(0);
    transition: transform linear 0;
  }
}
.header__menu .region-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .header__menu .region-menu {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.header__menu .block-menu {
  display: flex;
  background: #000000;
}
@media (min-width: 992px) {
  .header__menu .block-menu {
    display: block;
    background: none;
  }
}
.header__menu .block-menu .content {
  width: 100%;
}
.header__menu ul.menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  height: 100%;
}
@media (min-width: 992px) {
  .header__menu ul.menu {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }
}
.header__menu ul.menu li {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.header__menu ul.menu li a:not(.button--primary) {
  color: #FFFFFF;
  font-size: 24px;
  padding: 40px;
  display: flex;
  padding: 20px;
  text-decoration: none;
  font-weight: 700;
  width: 100%;
  transition: all ease-out 0.125s;
}
@media (min-width: 992px) {
  .header__menu ul.menu li a:not(.button--primary) {
    font-size: 16px;
    padding: 30px 20px;
    width: auto;
  }
}
.header__menu ul.menu li a:not(.button--primary):hover, .header__menu ul.menu li a:not(.button--primary):focus {
  color: #FFB45F;
  background: #000000;
  transition: all ease-out 0.075s;
}
@media (min-width: 992px) {
  .header__menu ul.menu li.expanded:hover ul, .header__menu ul.menu li.expanded:focus ul {
    max-height: 500%;
    transition: max-height 0.25s ease-out;
  }
}
@media (min-width: 992px) {
  .header__menu ul.menu li.expanded a:hover + ul,
  .header__menu ul.menu li.expanded a:focus + ul {
    max-height: 500%;
    transition: max-height 0.25s ease-out;
  }
}
.header__menu ul.menu li.expanded ul {
  padding-left: 20px;
}
@media (min-width: 992px) {
  .header__menu ul.menu li.expanded ul {
    padding: 0;
    max-height: 0;
    display: flex;
    position: absolute;
    overflow: hidden;
    flex-direction: column;
    background-color: #33333A;
    transition: max-height 0.125s ease-out;
  }
}
.header__menu ul.menu li.expanded ul a:not(.button--primary) {
  font-size: 20px;
  padding: 10px 20px;
}
@media (min-width: 992px) {
  .header__menu ul.menu li.expanded ul a:not(.button--primary) {
    font-size: 16px;
    padding: 20px;
    min-width: 180px;
  }
}
.header__menu #block-system-main-menu {
  height: calc(100% - 138px);
  overflow: scroll;
  border-top: 4px solid #FF8F12;
}
@media (min-width: 992px) {
  .header__menu #block-system-main-menu {
    height: auto;
    overflow: visible;
    padding: 0 0 0 260px;
    border-top: 0;
  }
}
.header__menu #block-system-user-menu {
  width: 100%;
  padding: 20px 20px 40px;
  position: fixed;
  bottom: 45px;
}
@media (min-width: 992px) {
  .header__menu #block-system-user-menu {
    width: auto;
    padding: 0 40px 0 20px;
    position: relative;
    bottom: auto;
  }
}
.header__menu #block-system-user-menu ul.menu {
  display: flex;
  flex-direction: row;
}
.header__menu #block-system-user-menu ul.menu li {
  width: 100%;
  margin-right: 20px;
}
.header__menu #block-system-user-menu ul.menu li:last-child {
  margin-right: 0;
}
.header__menu #block-system-user-menu ul.menu li a {
  width: 100%;
}
.header__menu #block-system-user-menu ul.menu li.last {
  width: auto;
}
.header__menu #block-system-user-menu ul.menu li.last a {
  width: auto;
}
.header .menu--open {
  display: block;
  height: 50px;
  width: 54px;
  margin: 7px 14px 0 0;
  text-indent: -9999px;
  overflow: hidden;
  background: url("/sites/all/themes/ccsoccer/images/menu--open.svg") no-repeat;
}
@media (min-width: 992px) {
  .header .menu--open {
    display: none;
  }
}
.header .menu--close {
  display: block;
  height: 48px;
  width: 48px;
  position: absolute;
  right: 10px;
  top: 8px;
  text-indent: -9999px;
  overflow: hidden;
  background: url("/sites/all/themes/ccsoccer/images/menu--close.svg") no-repeat;
}
@media (min-width: 992px) {
  .header .menu--close {
    display: none;
  }
}

.site {
  overflow: hidden;
}
.site__main {
  padding: 30px 20px;
}
@media (min-width: 992px) {
  .site__main {
    padding: 40px;
    min-height: calc(100vh - 670px);
  }
}

#block-system-main > h2 {
  display: none;
}

.field-name-body h1, .field-name-body h2, .field-name-body h3, .field-name-body h4, .field-name-body h5, .field-name-body h6 {
  padding: 0 0 20px;
}
.field-name-body p + p {
  padding: 20px 0 0;
}
.field-name-body ol, .field-name-body ul {
  padding: 20px 0;
}
.field-name-body ol li, .field-name-body ul li {
  list-style: circle;
  margin-left: 20px;
  line-height: 1.75;
}

.footer {
  position: relative;
  border-top: 4px solid #FF8F12;
  background: #33333A;
  z-index: 150;
}
@media (min-width: 992px) {
  .footer {
    border-top: 7px solid #FF8F12;
  }
}
.footer__content {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}
@media (min-width: 992px) {
  .footer__content {
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 40px;
  }
}
.footer__menus .menu {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (min-width: 992px) {
  .footer__menus .menu {
    flex-direction: row;
    text-align: left;
  }
}
.footer__menus .menu .expanded {
  padding: 0;
}
.footer__menus .menu .expanded + .expanded {
  padding: 40px 0 0;
}
@media (min-width: 992px) {
  .footer__menus .menu .expanded + .expanded {
    padding: 0 0 0 80px;
  }
}
.footer__menus .menu .menu {
  flex-direction: column;
}
.footer__menus .nolink {
  display: block;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  padding: 0 0 20px;
}
.footer__menus a {
  display: block;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  text-decoration: none;
}
.footer__menus a:hover, .footer__menus a:focus, .footer__menus a.active {
  color: #FFB45F;
  text-decoration: underline;
}
.footer__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 0;
}
@media (min-width: 992px) {
  .footer__links {
    padding: 0;
  }
}
.footer__logo {
  display: block;
  padding: 5px;
  text-indent: -9999px;
  overflow: hidden;
  background: url("/sites/all/themes/ccsoccer/images/logo--color.svg") no-repeat;
  width: 145px;
  height: 160px;
  z-index: 300;
  transition: transform ease-out 0.25s;
}
.footer__logo:hover, .footer__logo:focus {
  transform: scale(1.025);
  transition: transform ease-out 0.125s;
}
.footer__social {
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
}
.footer__social a {
  margin: 0 10px;
}
.footer__social a:hover path, .footer__social a:focus path {
  fill: #FFB45F;
}
.footer__legal {
  padding: 20px;
  font-weight: 700;
  background: #000000;
}
@media (min-width: 992px) {
  .footer__legal {
    padding: 20px;
  }
}
.footer__legal p {
  margin: 0;
  padding: 0;
  color: #D6D6D9;
  font-size: 12px;
  text-align: center;
}

.page-league-manager-my-groups .view-my-groups {
  padding: 20px 0;
}
.page-league-manager-my-groups .view-my-groups td, .page-league-manager-my-groups .view-my-groups th {
  padding: 10px;
}

.view-league-summary {
  padding: 20px 0 0;
}
@media (min-width: 768px) {
  .view-league-summary {
    padding: 40px 0 0;
  }
}
.view-league-summary .view-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .view-league-summary .view-content {
    flex-direction: row;
  }
}
.view-league-summary .views-row {
  width: 100%;
}
@media (min-width: 768px) {
  .view-league-summary .views-row {
    width: 50%;
  }
}
.view-league-summary .views-row + .views-row {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .view-league-summary .views-row:nth-child(odd) {
    padding: 0 20px 0 0;
  }
}
@media (min-width: 768px) {
  .view-league-summary .views-row:nth-child(even) {
    padding: 0 0 0 20px;
  }
}
.view-league-summary .field-name-field-images {
  padding: 20px 0;
}
.view-league-summary .field-name-field-images img {
  border-radius: 5px;
}
.view-league-summary .field-name-body .button {
  margin-top: 10px;
}

.locations {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .locations {
    flex-direction: row;
  }
}

.location {
  width: 100%;
}
@media (min-width: 768px) {
  .location {
    width: 50%;
  }
}
.location + .location {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .location:nth-child(odd) {
    padding: 0 20px 0 0;
  }
}
@media (min-width: 768px) {
  .location:nth-child(even) {
    padding: 0 0 0 20px;
  }
}
.location iframe {
  padding: 20px 0 0;
}

.login-register {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 25px;
  background: #FFFFFF;
  color: #B80000;
  cursor: pointer;
  transition: all ease-out 0.125s;
  margin-top: 20px;
}
.login-register:hover, .login-register:focus {
  color: #FFFFFF;
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}

.view-pre-registration .view-empty {
  text-align: center;
}
.view-pre-registration .view-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .view-pre-registration .view-content {
    flex-direction: row;
  }
}
.view-pre-registration .views-field-title {
  padding: 0 0 20px;
}
.view-pre-registration .views-row {
  width: 100%;
}
@media (min-width: 768px) {
  .view-pre-registration .views-row {
    width: 50%;
  }
}
.view-pre-registration .views-row + .views-row {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .view-pre-registration .views-row:nth-child(odd) {
    padding: 0 20px 0 0;
  }
}
@media (min-width: 768px) {
  .view-pre-registration .views-row:nth-child(even) {
    padding: 0 0 0 20px;
  }
}
.view-pre-registration .league__row {
  display: flex;
  align-items: center;
}
.view-pre-registration .league__row + .league__row {
  padding: 10px 0 0;
}
.view-pre-registration .league__row > span {
  font-weight: 900;
  padding-right: 0.5em;
}
.view-pre-registration .league__row p {
  line-height: 1.2;
}
.view-pre-registration .views-field-php a {
  margin-top: 25px;
}
.view-pre-registration .spots {
  padding: 10px;
  margin: 10px 0 5px;
}
.view-pre-registration .spots--open {
  background: #8DF38D;
}
.view-pre-registration .spots--limited {
  background: #FFCC94;
}
.view-pre-registration .spots--none {
  background: #FF9494;
}
.view-pre-registration a.ctools-use-modal {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 40px;
  background: #B80000;
  color: #FFFFFF;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
.view-pre-registration a.ctools-use-modal:hover, .view-pre-registration a.ctools-use-modal:focus {
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}
.view-pre-registration a.waitlist {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 40px;
  border: 2px solid #B80000;
  border-radius: 25px;
  background: #FFFFFF;
  color: #B80000;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
.view-pre-registration a.waitlist:hover, .view-pre-registration a.waitlist:focus {
  color: #FFFFFF;
  background: #6D0000;
  border-color: #6D0000;
  transition: all ease-out 0.075s;
}
.view-pre-registration .attachment-before {
  display: none;
}

.page-user-edit .tabs.primary,
.page-user-edit .tabs.secondary {
  display: none;
}

#modalContent {
  border-radius: 10px;
}
#modalContent .modal-header {
  border-radius: 10px 10px 0 0;
  background-color: #000000;
  border-bottom: 4px solid #FF8F12;
  padding: 2px 2px 2px 10px;
}
#modalContent .form-actions {
  max-height: 400px;
}
#modalContent .form-item-payment-method {
  display: none;
}
#modalContent #payment-details {
  border: 1px solid #aaa;
  padding: 10px;
  border-radius: 10px;
  background: #E6E6EA;
  width: 450px;
}

.ctools-modal-content {
  border-radius: 10px;
}
.ctools-modal-content .wizard-trail {
  font-size: 12px;
}

.modal-header {
  background-color: #000000;
}
.modal-header .close img {
  visibility: hidden;
}

.modal-form-wrapper {
  min-height: 200px;
}

#league-manager-registration-summary-form tr {
  background: #FFFFFF;
}
#league-manager-registration-summary-form tr.odd {
  background: #E6E6EA;
}

.page-league-manager-roster-builder {
  position: relative;
}
.page-league-manager-roster-builder #site {
  overflow: initial;
}
.page-league-manager-roster-builder #page-title {
  display: none;
}
.page-league-manager-roster-builder .site__main {
  max-width: 100%;
  padding: 0;
}

.roster__header {
  padding: 20px 40px;
}
.roster__help {
  padding: 10px 0 0;
}

#league-manager-player-canvas {
  height: 8000px;
  background: #E6E6EA;
  padding: 40px 40px 4px 240px;
}
#league-manager-player-canvas:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, transparent, #E6E6EA);
}

.team {
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 200px;
  margin: 10px;
  float: left;
  font-size: 12px;
  font-weight: 700;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 1px 2px 40px rgba(214, 214, 217, 0.5);
}
.team--workbench {
  position: sticky;
  margin-left: -210px;
  top: 40px;
}
.team--workbench .team__name {
  color: #FFFFFF;
  background: #33333A;
  border-radius: 5px 5px 0 0;
  width: 100%;
}
.team--workbench .team__name:hover {
  text-decoration: none;
}
.team--workbench .workbench__average {
  padding: 10px;
  text-align: center;
  background: #F6F6FA;
}
.team__name {
  align-self: center;
  text-transform: uppercase;
  color: #6D0000;
  font-family: "Open Sans", system-ui, sans-serif;
  line-height: 1.2;
  font-size: 1.2em;
  text-align: center;
  padding: 10px 4px 6px;
  text-decoration: none;
}
.team__name:hover {
  text-decoration: underline;
}
.team__body {
  height: 100%;
  padding: 0 4px;
}

.player {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  white-space: nowrap;
  height: 1.75em;
  margin: 5px 0;
}
.player__skill {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  width: 1.75em;
  height: 100%;
}
.player__name {
  padding-left: 0.35em;
}
.player--male .player__skill {
  background: #055274;
}
.player--female .player__skill {
  background: #B80000;
}

.group {
  margin: 5px 0;
  border-left: 5px solid #33333A;
}
.group__edit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background: #33333A;
  text-decoration: none;
  width: 1.75em;
}
.group__edit:hover, .group__edit:focus {
  background: #009300;
}
.group .player {
  margin: 0;
}
.group.locked {
  cursor: not-allowed;
  background: repeating-linear-gradient(45deg, transparent, transparent 3px, #F6F6FA 3px, #F6F6FA 6px);
}
.group.locked.ui-draggable-dragging {
  background: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 3px, #F6F6FA 3px, #F6F6FA 6px);
}

.stats {
  display: flex;
  background: #F6F6FA;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.stat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33333%;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.stat__skill {
  background: #FF9494;
}
.stat__count {
  color: #FFFFFF;
  background: #33333A;
}

.skill-board {
  display: flex;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.skill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  background: #FF9494;
  width: 16.6666666667%;
}
.skill:hover {
  text-decoration: underline;
}
.skill--2 {
  filter: hue-rotate(30deg);
}
.skill--3 {
  filter: hue-rotate(60deg);
}
.skill--4 {
  filter: hue-rotate(90deg);
}
.skill--5 {
  filter: hue-rotate(120deg);
}
.skill--reset {
  color: #FFFFFF;
  background: #33333A;
}
.skill--reset:hover {
  text-decoration: none;
  background: #B80000;
}

.ui-draggable {
  cursor: move;
}

.ui-draggable-dragging {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  z-index: 600;
}

.display--large#league-manager-player-canvas {
  padding: 40px 40px 4px 360px;
}
.display--large .team {
  font-size: 20px;
  width: 290px;
  height: 850px;
}
.display--large .team--workbench {
  margin-left: -300px;
}
.display--large .player {
  font-size: 20px;
}
.display--large .stat,
.display--large .skill {
  font-size: 24px;
}

.display--skill .player {
  opacity: 0.35;
}
.display--skill .group {
  border-left: 5px solid #E6E6EA;
}
.display--skill .group__edit {
  background: #E6E6EA;
}
.display--skill--1 .player__skill--1 {
  opacity: 1;
}
.display--skill--2 .player__skill--2 {
  opacity: 1;
}
.display--skill--3 .player__skill--3 {
  opacity: 1;
}
.display--skill--4 .player__skill--4 {
  opacity: 1;
}
.display--skill--5 .player__skill--5 {
  opacity: 1;
}

.schedule {
  position: relative;
  margin: 0 auto;
}
.schedule__container {
  position: relative;
}
.schedule__reel {
  overflow-x: hidden;
  overflow-y: visible;
}
.schedule__table {
  width: 100%;
  margin-top: 30px;
  table-layout: fixed;
}
.schedule__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 186px;
  height: 100%;
  z-index: 99;
  text-indent: -99999px;
  position: absolute;
  top: 0;
  opacity: 0.25;
  cursor: pointer;
}
.schedule__arrow:hover {
  background-color: #FF8F12;
  opacity: 0.75;
}
.schedule__arrow:before {
  content: "";
  display: flex;
  height: 120px;
  width: 120px;
  background: url(/sites/all/themes/ccsoccer/images/chevron.svg);
  background-position: center;
  background-size: cover;
}
.schedule__arrow--prev {
  left: 0;
}
.schedule__arrow--prev:before {
  transform: rotate(180deg);
}
.schedule__arrow--next {
  right: 0;
}
.schedule__team {
  height: 20px;
}
.schedule__team--home {
  background: #FF9494;
}
.schedule__team--away {
  background: #FFFFFF;
}
.schedule__team--mine:before {
  content: "";
  width: 20px;
  height: 20px;
  margin: -2px 4px 0 0;
  display: inline-block;
  background-image: url(/sites/default/files/soccer-ball-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}
.schedule th {
  width: 186px;
  height: 40px;
  background: #E6E6EA;
  border-left: 10px solid #FFFFFF;
  border-right: 10px solid #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schedule th.header-0 {
  position: absolute;
}
.schedule tr {
  padding-bottom: 2px;
  display: inline-flex;
}
.schedule td {
  vertical-align: middle;
  width: 186px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  border-left: 10px solid #005800;
  border-right: 10px solid #005800;
}
.schedule td.cell-0 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005800;
  color: #FFFFFF;
}

.game-status {
  padding: 12px 12px 12px 69px;
  background: #33333A;
  position: relative;
}
@media (min-width: 992px) {
  .game-status {
    width: calc(100% - 300px);
    margin: 0 40px 0 260px;
    border-radius: 5px;
  }
}
.game-status:before {
  content: "";
  background: #000000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 55px;
}
@media (min-width: 992px) {
  .game-status:before {
    border-radius: 5px 0 0 5px;
  }
}
.game-status:after {
  content: "";
  background: url("/sites/all/themes/ccsoccer/images/calendar.svg") no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  left: 12px;
}
.game-status p {
  color: #FFFFFF;
  padding: 0;
  margin: 0;
  line-height: 1.4;
}
.game-status__details {
  font-size: 13px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .game-status__details {
    font-size: 16px;
  }
}
.game-status__disclaimer {
  font-size: 10px;
  font-weight: 400;
}
.game-status--tbd {
  background: #E6E6EA;
}
.game-status--tbd:before {
  background: #D6D6D9;
}
.game-status--tbd:after {
  background: url("/sites/all/themes/ccsoccer/images/clock.svg") no-repeat;
}
.game-status--tbd p {
  color: #33333A;
}
.game-status--on {
  background: #009300;
}
.game-status--on:before {
  background: #005800;
}
.game-status--on:after {
  background: url("/sites/all/themes/ccsoccer/images/thumb.svg") no-repeat;
}
.game-status--off {
  background: #B80000;
}
.game-status--off:before {
  background: #6D0000;
}
.game-status--off:after {
  background: url("/sites/all/themes/ccsoccer/images/thumb.svg") no-repeat;
  transform: rotate(180deg);
}

.view-view-teams {
  padding: 10px 0 0;
}
.view-view-teams .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.view-view-teams .item-list {
  flex-grow: 1;
  width: calc(100% - 20px);
  float: left;
  padding: 5px;
  margin: 10px;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 1px 2px 40px rgba(214, 214, 217, 0.5);
  flex-grow: 0;
}
@media (min-width: 576px) {
  .view-view-teams .item-list {
    width: calc(50% - 20px);
  }
}
@media (min-width: 768px) {
  .view-view-teams .item-list {
    width: calc(33.333% - 20px);
  }
}
@media (min-width: 992px) {
  .view-view-teams .item-list {
    width: calc(25% - 20px);
  }
}
.view-view-teams .item-list h3 {
  font-size: 22px;
  text-align: center;
  padding: 10px 0 20px;
}
.view-view-teams .item-list ul li {
  text-transform: capitalize;
  padding: 5px 20px;
  margin: 0;
}
.view-view-teams .player-0 {
  font-weight: 700;
  background: #009300;
  color: #FFFFFF;
  padding: 10px 20px;
  text-align: center;
}

.schedule__utils {
  padding: 40px 0 0;
}

.messages.messages {
  position: relative;
  padding: 10px 20px 10px 65px;
  margin: 0;
  border: 0;
  color: #FFFFFF;
  font-weight: 700;
}
@media (min-width: 768px) {
  .messages.messages {
    padding: 10px 65px;
  }
}
.messages.messages:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50px;
}
.messages.messages:after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: calc(50% - 12px);
  left: 13px;
}
.messages.messages a {
  color: #FFFFFF;
  text-decoration: underline;
}
.messages.messages .placeholder {
  padding: 0 0.25em;
  text-decoration: underline;
}
.messages.messages.status {
  display: flex;
  justify-content: center;
  background: #009300;
}
.messages.messages.status:before {
  background: #005800;
}
.messages.messages.status:after {
  background: url("/sites/all/themes/ccsoccer/images/approve.svg") no-repeat;
}
.messages.messages.warning {
  display: flex;
  justify-content: center;
  background: #B86100;
  font-weight: 400;
}
.messages.messages.warning:before {
  background: #6D3A00;
}
.messages.messages.warning:after {
  background: url("/sites/all/themes/ccsoccer/images/deny.svg") no-repeat;
}
.messages.messages.error {
  background: #B80000;
}
.messages.messages.error:before {
  background: #6D0000;
}
.messages.messages.error:after {
  background: url("/sites/all/themes/ccsoccer/images/deny.svg") no-repeat;
}

.tabs.tabs {
  border: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background: #1b1b1f;
}
.tabs.tabs li {
  padding: 0;
  margin: 0;
}
.tabs.tabs li a,
.tabs.tabs li span,
.tabs.tabs li a.active {
  display: block;
  background: #1b1b1f;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0;
  border: 0;
  cursor: pointer;
  transition: all ease-out 0.125s;
}
@media (min-width: 768px) {
  .tabs.tabs li a,
  .tabs.tabs li span,
  .tabs.tabs li a.active {
    font-size: 16px;
    padding: 10px 20px;
  }
}
.tabs.tabs li a:hover, .tabs.tabs li a:focus-within,
.tabs.tabs li span:hover,
.tabs.tabs li span:focus-within,
.tabs.tabs li a.active:hover,
.tabs.tabs li a.active:focus-within {
  color: #FFB45F;
  background: #000000;
  transition: all ease-out 0.075s;
}

.block-masquerade {
  display: flex;
  justify-content: center;
  padding: 0 0 40px;
}
@media (min-width: 768px) {
  .block-masquerade {
    justify-content: flex-start;
  }
}
.block-masquerade .form-item {
  margin: 0;
  padding-right: 10px;
}
.block-masquerade .description {
  padding: 4px 0 0;
  color: #D6D6D9;
}