.messages.messages {
  position: relative;
  padding: 10px 20px 10px 65px;
  margin: 0;
  border: 0;
  color: $white;
  font-weight: 700;

  @include breakpoint(md) {
    padding: 10px 65px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50px;
  }

  &:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(50% - 12px);
    left: 13px;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  .placeholder {
    padding: 0 0.25em;
    text-decoration: underline;
  }

  &.status {
    display: flex;
    justify-content: center;
    background: $green-dark;

    &:before {
      background: $green-darkest;
    }

    &:after {
      background: url('/sites/all/themes/ccsoccer/images/approve.svg') no-repeat;
    }
  }

  &.warning {
    display: flex;
    justify-content: center;
    background: $orange-dark;
    font-weight: 400;

    &:before {
      background: $orange-darkest;
    }

    &:after {
      background: url('/sites/all/themes/ccsoccer/images/deny.svg') no-repeat;
    }
  }

  &.error {
    background: $red-dark;

    &:before {
      background: $red-darkest;
    }

    &:after {
      background: url('/sites/all/themes/ccsoccer/images/deny.svg') no-repeat;
    }
  }
}

.tabs.tabs {
  border: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background: darken($gray-dark, 10%);

  li {
    padding: 0;
    margin: 0;

    a,
    span,
    a.active {
      display: block;
      background: darken($gray-dark, 10%);
      color: $white;
      font-weight: 700;
      font-size: 14px;
      padding: 5px 10px;
      margin: 0;
      border: 0;
      cursor: pointer;
      transition: all ease-out .125s;

      @include breakpoint(md) {
        font-size: 16px;
        padding: 10px 20px;
      }

      &:hover,
      &:focus-within {
        color: $orange-light;
        background: $black;
        transition: all ease-out .075s;
      }
    }
  }
}
