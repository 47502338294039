.fieldset-legend.fieldset-legend.fieldset-legend {
  display: none;
}

.url-textfield,
.login-account {
  display: none;
}

.form-item {

  input,
  select {
    outline: 0;
    outline: 0;
    padding: 10px;
    border: 2px solid $gray;
    border-radius: 3px;
  }

  .description {
    color: $gray-dark;
    font-size: 10px;
  }
}
