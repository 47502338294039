.page-league-manager-roster-builder {
  position: relative;

  #site {
    overflow: initial;
  }

  #page-title {
    display: none;
  }

  .site__main {
    max-width: 100%;
    padding: 0;
  }
}

.roster {

  &__header {
    padding: 20px 40px;
  }

  &__help {
    padding: 10px 0 0;
  }
}

#league-manager-player-canvas {
  height: 8000px;
  background: $gray-light;
  padding: 40px 40px 4px 240px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 500px;
    width: 100%;
    background: linear-gradient(180deg, transparent, $gray-light);
  }
}

.team {
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 200px;
  margin: 10px;
  float: left;
  font-size: 12px;
  font-weight: 700;
  background: $white;
  border-radius: 5px;
  box-shadow: 1px 2px 40px rgba($gray, .5);

  &--workbench {
    position: sticky;
    margin-left: -210px;
    top: 40px;

    .team__name {
      color: $white;
      background: $gray-dark;
      border-radius: 5px 5px 0 0;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }

    .workbench__average {
      padding: 10px;
      text-align: center;
      background: $off-white;
    }
  }

  &__name {
    align-self: center;
    text-transform: uppercase;
    color: $red-darkest;
    font-family: $font-open-sans;
    line-height: 1.2;
    font-size: 1.2em;
    text-align: center;
    padding: 10px 4px 6px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__body {
    height: 100%;
    padding: 0 4px
  }
}

.player {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  white-space: nowrap;
  height: 1.75em;
  margin: 5px 0;

  &__skill {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: $white;
    width: 1.75em;
    height: 100%;
  }

  &__name {
    padding-left: .35em;
  }

  &--male {

    .player__skill {
      background: $blue-dark;
    }
  }

  &--female {

    .player__skill {
      background: $red-dark;
    }
  }
}

.group {
  margin: 5px 0;
  border-left: 5px solid $gray-dark;

  &__edit {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background: $gray-dark;
    text-decoration: none;
    width: 1.75em;

    &:hover,
    &:focus {
      background: $green-dark;
    }
  }

  .player {
    margin: 0;
  }

  &.locked {
    cursor: not-allowed;
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 3px,
      $off-white 3px,
      $off-white 6px
    );

    &.ui-draggable-dragging {
      background: repeating-linear-gradient(
        45deg,
        rgba($white, .8),
        rgba($white, .8) 3px,
        $off-white 3px,
        $off-white 6px
      )
    }
  }
}

.stats {
  display: flex;
  background: $off-white;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.stat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33333%;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

  &__skill {
    background: $red-lightest;
  }

  &__count {
    color: $white;
    background: $gray-dark;
  }
}

.skill-board {
  display: flex;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.skill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  background: $red-lightest;
  width: calc(100% / 6);

  &:hover {
    text-decoration: underline;
  }

  &--2 {
    filter: hue-rotate(30deg)
  }

  &--3 {
    filter: hue-rotate(60deg)
  }

  &--4 {
    filter: hue-rotate(90deg)
  }

  &--5 {
    filter: hue-rotate(120deg)
  }

  &--reset {
    color: $white;
    background: $gray-dark;

    &:hover {
      text-decoration: none;
      background: $red-dark;
    }
  }
}

.ui-draggable {
  cursor: move;
}

.ui-draggable-dragging {
  background: rgba($white, .8);
  border-radius: 2px;
  z-index: 600;
}

.display--large {

  &#league-manager-player-canvas {
    padding: 40px 40px 4px 360px;
  }

  .team {
    font-size: 20px;
    width: 290px;
    height: 850px;

    &--workbench {
      margin-left: -300px;
    }
  }

  .player {
    font-size: 20px;
  }

  .stat,
  .skill {
    font-size: 24px;
  }
}

.display--skill {

  .player {
    opacity: .35;
  }

  .group {
    border-left: 5px solid $gray-light;
  }

  .group__edit {
    background: $gray-light;
  }

  &--1 {

    .player__skill--1 {
      opacity: 1;
    }
  }

  &--2 {

    .player__skill--2 {
      opacity: 1;
    }
  }

  &--3 {

    .player__skill--3 {
      opacity: 1;
    }
  }

  &--4 {

    .player__skill--4 {
      opacity: 1;
    }
  }

  &--5 {

    .player__skill--5 {
      opacity: 1;
    }
  }
}
