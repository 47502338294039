.footer {
  position: relative;
  border-top: 4px solid $orange;
  background: $gray-dark;
  z-index: 150;

  @include breakpoint(lg) {
    border-top: 7px solid $orange;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;

    @include breakpoint(lg) {
      flex-direction: row;
      justify-content: space-between;
      padding: 60px 40px;
    }
  }

  &__menus {

    .menu {
      display: flex;
      flex-direction: column;
      text-align: center;

      @include breakpoint(lg) {
        flex-direction: row;
        text-align: left;
      }

      .expanded {
        padding: 0;

        + .expanded {
          padding: 40px 0 0;

          @include breakpoint(lg) {
            padding: 0 0 0 80px;
          }
        }
      }

      .menu {
        flex-direction: column;
      }
    }

    .nolink {
      display: block;
      color: $white;
      font-weight: 700;
      font-size: 20px;
      padding: 0 0 20px;
    }

    a {
      display: block;
      color: $white;
      font-weight: 400;
      font-size: 16px;
      padding: 10px 0;
      text-decoration: none;

      &:hover,
      &:focus,
      &.active {
        color: $orange-light;
        text-decoration: underline;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0;

    @include breakpoint(lg) {
      padding: 0;
    }
  }

  &__logo {
    display: block;
    padding: 5px;
    text-indent: -9999px;
    overflow: hidden;
    background: url('/sites/all/themes/ccsoccer/images/logo--color.svg') no-repeat;
    width: 145px;
    height: 160px;
    z-index: 300;
    transition: transform ease-out .25s;

    &:hover,
    &:focus {
      transform: scale(1.025);
      transition: transform ease-out .125s;
    }
  }

  &__social {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;

    a {
      margin: 0 10px;

      &:hover path,
      &:focus path {
        fill: $orange-light;
      }
    }
  }

  &__legal {
    padding: 20px;
    font-weight: 700;
    background: $black;

    @include breakpoint(lg) {
      padding: 20px;
    }

    p {
      margin: 0;
      padding: 0;
      color: $gray;
      font-size: 12px;
      text-align: center;
    }
  }
}
