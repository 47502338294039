.site {
  overflow: hidden;

  &__main {

    padding: 30px 20px;

    @include breakpoint(lg) {
      padding: 40px;
      min-height: calc(100vh - 670px);
    }
  }
}

#block-system-main > h2 {
  display: none;
}

.field-name-body {

  h1, h2, h3, h4, h5, h6 {
    padding: 0 0 20px;
  }

  p + p {
    padding: 20px 0 0;
  }

  ol, ul {
    padding: 20px 0;

    li {
      list-style: circle;
      margin-left: 20px;
      line-height: 1.75;
    }
  }
}
