.view-view-teams {
  padding: 10px 0 0;

  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .item-list {
    flex-grow: 1;
    width: calc(100% - 20px);
    float: left;
    padding: 5px;
    margin: 10px;
    background: $white;
    border-radius: 5px;
    box-shadow: 1px 2px 40px rgba($gray, .5);
    flex-grow: 0;

    @include breakpoint(sm) {
      width: calc(50% - 20px);
    }

    @include breakpoint(md) {
      width: calc(33.333% - 20px);
    }

    @include breakpoint(lg) {
      width: calc(25% - 20px);
    }

    h3 {
      font-size: 22px;
      text-align: center;
      padding: 10px 0 20px;
    }

    ul li {
      text-transform: capitalize;
      padding: 5px 20px;
      margin: 0;
    }
  }

  .player-0 {
    font-weight: 700;
    background: $green-dark;
    color: $white;
    padding: 10px 20px;
    text-align: center;
  }
}

.schedule__utils {
  padding: 40px 0 0;
}
