.header {
  display: flex;
  justify-content: space-around;
  background: $gray-dark;
  border-bottom: 4px solid $orange;
  position: relative;
  z-index: 200;

  @include breakpoint(lg) {
    display: block;
    margin-bottom: 20px;
    border-bottom: 7px solid $orange;
  }

  .container {
    position: relative;
    height: 100%;
    //min-height: -webkit-fill-available; // may solve mobile viewport height discrepancy

    @include breakpoint(lg) {
      height: auto;
    }
  }

  &__logo {
    position: relative;
    z-index: 500;
    display: block;
    height: 80px;
    width: 70px;
    margin: 5px 0 -20px 20px;
    text-indent: -9999px;
    overflow: hidden;
    background: url('/sites/all/themes/ccsoccer/images/logo--color.svg') no-repeat;
    transition: transform ease-out .25s;

    @include breakpoint(lg) {
      position: absolute;
      top: 10px;
      left: 40px;
      height: 200px;
      width: 180px;
      margin: 0;
    }

    &:hover,
    &:focus {

      @include breakpoint(lg) {
        transform: scale(1.025);
        transition: transform ease-out .125s;
      }
    }
  }

  &__slogan {
    display: none;
    color: $white;
    background: $black;
    font-weight: 400;
    margin: 0;

    @include breakpoint(lg) {
      display: block;
    }

    p {
      display: block;
      padding: 10px 10px 10px 280px;
    }
  }

  &__menu {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 65px 0 0;
    position: absolute;
    top: 0;
    right: -100vw;
    height: 100vh;
    width: 100vw;
    background: $gray-dark;
    z-index: 300;
    transform: translateX(0);
    transition: transform ease-out .25s;

    @include breakpoint(lg) {
      overflow: unset;
      padding: 0;
      position: relative;
      align-items: flex-start;
      top: auto;
      right: auto;
      height: auto;
      width: auto;
      background: transparent;
      transform: translateX(0);
      transition: transform linear 0;
    }

    &.active {
      transform: translateX(-100%);
      transition: transform ease-out .125s;

      @include breakpoint(lg) {
        transform: translateX(0);
        transition: transform linear 0;
      }
    }

    .region-menu {
      height: 100%;
      display: flex;
      flex-direction: column;

      @include breakpoint(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    .block-menu {
      display: flex;
      background: $black;

      @include breakpoint(lg) {
        display: block;
        background: none;
      }

      .content {
        width: 100%;
      }
    }

    ul.menu {
      display: flex;
      flex-direction: column;
      list-style: none;
      height: 100%;

      @include breakpoint(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: auto;
      }

      li {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;

        a:not(.button--primary) {
          color: $white;
          font-size: 24px;
          padding: 40px;
          display: flex;
          padding: 20px;
          text-decoration: none;
          font-weight: 700;
          width: 100%;
          transition: all ease-out .125s;

          @include breakpoint(lg) {
            font-size: 16px;
            padding: 30px 20px;
            width: auto;
          }

          &:hover,
          &:focus {
            color: $orange-light;
            background: $black;
            transition: all ease-out .075s;
          }
        }

        &.expanded {

          &:hover,
          &:focus {

            ul {

              @include breakpoint(lg) {
                max-height: 500%;
                transition: max-height .25s ease-out;
              }
            }
          }

          a:hover,
          a:focus {

            + ul {

              @include breakpoint(lg) {
                max-height: 500%;
                transition: max-height .25s ease-out;
              }
            }
          }

          ul {
            padding-left: 20px;

            @include breakpoint(lg) {
              padding: 0;
              max-height: 0;
              display: flex;
              position: absolute;
              overflow: hidden;
              flex-direction: column;
              background-color: $gray-dark;
              transition: max-height .125s ease-out;
            }

            a:not(.button--primary) {
              font-size: 20px;
              padding: 10px 20px;

              @include breakpoint(lg) {
                font-size: 16px;
                padding: 20px;
                min-width: 180px;
              }
            }
          }
        }
      }
    }

    #block-system-main-menu {
      height: calc(100% - 138px);
      overflow: scroll;
      border-top: 4px solid $orange;

      @include breakpoint(lg) {
        height: auto;
        overflow: visible;
        padding: 0 0 0 260px;
        border-top: 0;
      }
    }

    #block-system-user-menu {
      width: 100%;
      padding: 20px 20px 40px;
      position: fixed;
      bottom: 45px;

      @include breakpoint(lg) {
        width: auto;
        padding: 0 40px 0 20px;
        position: relative;
        bottom: auto;
      }

      ul.menu {
        display: flex;
        flex-direction: row;

        li {
          width: 100%;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          a {
            width: 100%;
          }

          &.last {
            width: auto;

            a {
              width: auto;
            }
          }
        }
      }
    }
  }

  .menu--open {
    display: block;
    height: 50px;
    width: 54px;
    margin: 7px 14px 0 0;
    text-indent: -9999px;
    overflow: hidden;
    background: url('/sites/all/themes/ccsoccer/images/menu--open.svg') no-repeat;

    @include breakpoint(lg) {
      display: none;
    }
  }

  .menu--close {
    display: block;
    height: 48px;
    width: 48px;
    position: absolute;
    right: 10px;
    top: 8px;
    text-indent: -9999px;
    overflow: hidden;
    background: url('/sites/all/themes/ccsoccer/images/menu--close.svg') no-repeat;

    @include breakpoint(lg) {
      display: none;
    }
  }
}
