#modalContent {
  border-radius: 10px;

  .modal-header {
    border-radius: 10px 10px 0 0;
    background-color: $black;
    border-bottom: 4px solid $orange;
    padding: 2px 2px 2px 10px;
  }

  .form-actions {
    max-height: 400px;
  }

  .form-item-payment-method {
    display: none;
  }

  #payment-details {
    border: 1px solid #aaa;
    padding: 10px;
    border-radius: 10px;
    background: $gray-light;
    width: 450px;
  }
}

.ctools-modal-content {
  border-radius: 10px;

  .wizard-trail {
    font-size: 12px;
  }
}

.modal-header {
  background-color: $black;

  .close {

    img {
      visibility: hidden;
    }
  }
}

.modal-form-wrapper {
  min-height: 200px;
}

#league-manager-registration-summary-form {

  tr {
    background: $white;
  }

  tr.odd {
    background: $gray-light;
  }
}
