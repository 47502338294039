.view-pre-registration {

  .view-empty {
    text-align: center;
  }

  .view-content {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .views-field-title {
    padding: 0 0 20px;
  }

  .views-row {
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }

    + .views-row {
      padding-top: 40px;
    }

    &:nth-child(odd) {

      @include breakpoint(md) {
        padding: 0 20px 0 0;
      }
    }

    &:nth-child(even) {

      @include breakpoint(md) {
        padding: 0 0 0 20px;
      }
    }
  }

  .league__row {
    display: flex;
    align-items: center;

    + .league__row {
      padding: 10px 0 0;
    }

    > span {
      font-weight: 900;
      padding-right: .5em;
    }

    p {
      line-height: 1.2;
    }
  }

  .views-field-php {

    a {
      margin-top: 25px;
    }
  }

  .spots {
    padding: 10px;
    margin: 10px 0 5px;

    &--open {
      background: $green-lightest;
    }

    &--limited {
      background: $orange-lightest;
    }

    &--none {
      background: $red-lightest;
    }
  }

  a.ctools-use-modal {
    @include button--primary;
  }

  a.waitlist {
    @include button--secondary;
  }

  .attachment-before {
    display: none;
  }
}
