.page-league-manager-my-groups {

  .view-my-groups {
    padding: 20px 0;

    td,th {
      padding: 10px;
    }
  }
}
